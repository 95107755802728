import { library } from '@fortawesome/fontawesome-svg-core';
import { faSmile, faStar } from '@fortawesome/free-regular-svg-icons';
import {
  faHandPeace,
  faLightbulb,
  faUsers,
  faSitemap,
  faHandshake,
  faBookReader,
  faEnvelope,
  faBriefcase,
  faStar as faStarS,
  faStarHalfAlt,
  faGraduationCap,
  faChalkboardTeacher,
  faChevronUp,
  faChevronDown,
  faFilePdf,
  faUserNinja,
  faPaperPlane,
  faUserGraduate,
  faGrinWink,
  faSearch,
  faDownload,
  faMagnet,
  faHourglassHalf,
  faFrown,
} from '@fortawesome/free-solid-svg-icons';
import {
  faInstagram,
  faFacebook,
  faLinkedin,
  faGithub,
  faSteam,
  faGitAlt,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faSmile,
  faHandPeace,
  faLightbulb,
  faUsers,
  faSitemap,
  faHandshake,
  faBookReader,
  faEnvelope,
  faInstagram,
  faFacebook,
  faLinkedin,
  faGithub,
  faBriefcase,
  faStar,
  faStarS,
  faStarHalfAlt,
  faGraduationCap,
  faChalkboardTeacher,
  faSteam,
  faChevronUp,
  faChevronDown,
  faFilePdf,
  faGitAlt,
  faSearch,
  faDownload,
  faMagnet,
  faHourglassHalf,
  faFrown,
  faUserNinja,
  faPaperPlane,
  faUserGraduate,
  faGrinWink,
);
